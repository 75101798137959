import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import  secureLocalStorage  from  "react-secure-storage";

export default function Loading() {
    const [lang, setLang] = useState(null);
    useEffect(() => {
        setLang(secureLocalStorage.getItem("lang"));
    }, []);
    return (
        <div className={styles.loading}>
            <div className={styles.eie}></div>
            <div className={styles.top}>
                <div className={styles.ee}></div>
            </div>

            {lang == 'en' || lang == '' ? <div className={styles.bottom}>
                <h4>Powered by</h4>
                <div className={styles.logos}>
                    <div className={styles.cf}></div>
                    <div className={styles.euEn}></div>
                </div>
                <p>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or FranceAgriMer. Neither the European Union nor the granting authority can be held responsible for them.</p>
            </div> : ''}
            
            {lang == 'zh-Hant-TW' ? <div className={styles.bottom}>
                <h4>Powered by</h4>
                <div className={styles.logos}>
                    <div className={styles.cf}></div>
                    <div className={styles.euTaiwan}></div>
                </div>
                <p>歐盟贊助。意見出自創作者，不代表歐盟或法國農業局之立場。歐盟與授權機構對此概不負責。</p>
            </div> : ''}

            {lang == 'ko' ? <div className={styles.bottom}>
                <h4>Powered by</h4>
                <div className={styles.logos}>
                    <div className={styles.cfKorea}></div>
                    <div className={styles.euKorea}></div>
                </div>
                <p>위 내용은 오직 저자의 견해이며 유럽 연합 혹은 프랑스 농수산업진흥공사의 의견을 반영하지 않습니다. 또한 유럽 연합과 집행위원회는 게재된 정보에 대해 어떠한 책임도 지지 않습니다.</p>
            </div> : ''}

            {lang == 'zh' ? <div className={styles.bottom}>
                <h4>Powered by</h4>
                <div className={styles.logos}>
                    <div className={styles.cfChina}></div>
                    <div className={styles.euChina}></div>
                </div>
                <p>所表达的观点和意见仅代表作者本人，不一定反映欧盟或法国农业部(FranceAgriMer)的观点。欧盟和授权机构均不对它们承担责任。</p>
            </div> : ''}
        </div>
    );
}

