import React, { useState, useEffect } from "react";
import Script from 'next/script';
import Image from "next/image";
import Link from "next/link";
import styles from "./home.module.scss";
import Loader from "@/components/loadingscreeen";
import secureLocalStorage  from  "react-secure-storage";


export default function Home() {
    const pathname = secureLocalStorage.getItem("pathname");
   
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const dataCommon = () => {
        if(pathname == null || pathname == '') {
            setLoading(true);
        }
        setTimeout(() => {
            setLoading(false); 
        }, 4000);
        (async() => {
            const lang = secureLocalStorage.getItem("lang");
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=*&locale=${lang}`).then((res) => res.json());
                // console.log(data);
                setData(data.data.attributes);
                secureLocalStorage.setItem("pathname", '');
            } catch(err) {
                console.log(err);
                setLoading(false);
                secureLocalStorage.setItem("pathname", '');
            }
        })();
    }
    
    useEffect(() => {
        dataCommon();
    }, []);
    return (
        <>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-BRV3W59EYF" />
            <Script id="google-analytics">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
        
                gtag('config', 'G-BRV3W59EYF');
                `}
            </Script>
            {(loading && (pathname == '/' || pathname == null || pathname == '')) ? <Loader /> : ''}
            <div className={styles.landing}>
                <div className="container">
                    <figure className={styles.logolanding}>
                        <Image
                            src="/logo.png"
                            alt="Sopexa"
                            width={266}
                            height={100}
                        />
                    </figure>
                    <div className={styles.cnt}>
                        <h1 dangerouslySetInnerHTML={{ __html : data?.landing?.title}}></h1>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html : data?.landing?.description}}></div>
                    </div>
                    <nav>
                        {data?.menu?.map((link, index) => (
                            <Link key={index} href={`/${link.url}`}><span>{link.text}</span><i></i></Link>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    );
}
